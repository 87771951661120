.navbar {
    display: flex;
    justify-content: space-between;
    background-color:  #18141c;
    padding: 10px;
    width: 100%;  /* Changed to 100% */
  }
  .navbar .dropdown-menu {
    background-color:  #18141c !important;
    border-bottom: 2px solid #f0b40c !important;
}
.navbar .dropdown-menu .nav-item:hover {
    background-color:  #18141c !important;
}

  .navbar-inner {  /* New inner container */
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: auto;  /* Center the inner container */
  }
  
  .navbar-brand {
    flex-grow: 0;  
  }
  
  .navbar .navbar-logo {
    width: 50px;
    height: auto;
  }
  
  .navbar .navbar-right {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  
  .navbar .nav-item,
  .navbar .dropdown-item {
    color: white !important;  /* Sets text color to white */
    text-decoration: none;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 16px;
  }
  .navbar .nav-link.dropdown-toggle {
    color: white !important;
}

.navbar .nav-link.dropdown-toggle:hover {
    border-radius: 4px;
    color: #f0b40c !important;
    transition: ease-in 200ms;
}
  .navbar .nav-item:hover,
  .navbar .dropdown-item:hover {
    border-radius: 4px;
    color: #f0b40c !important;
    transition: ease-in 200ms;
  }
  
  .navbar .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .navbar .dropdown-content {
    display: none;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    top: 100%;
    left: 0;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 0;
  }
  