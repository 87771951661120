.text-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 2px solid black;
    border-radius: 8px;
    padding: 20px;
    margin: 10px;
    width: 100%;
    background-color: #1a1c24;
    min-width: initial;
}

.text-container {
    background-color: #18141c !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px;
    font-family: Flama, sans-serif;
    color: white;
}

.margin {
    margin-top: 20px !important;
}

.text-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.text-bg {
    background-color: #18141c !important;
}

.video-container {
    margin-top: 20px;
    background-color: #f0b40c;
    border-radius: 10px;
    padding: 6px;
    padding-bottom: 0px !important;
}
.video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    overflow: hidden; 
    border: 6px solid #f0b40c;
    margin-top: 20px;
    width: 100%;
    border-radius: 10px;
}


.video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.header-margintop {
    margin-top: 40px;
    text-align: center;
}

.header {
    margin-top: 40px;
    text-align: center;
}

.header-marginbot {
    margin-bottom: 40px !important;
}

.header-left {
    justify-self: flex-start;
    align-self: flex-start;
    font-size: 20px;
}

.text-bg,
.text-container,
.text-card-container {
    margin: 0;
    padding: 0;
}

.text-marginbot {
    margin-bottom: 30px;
}

.img-text {
    width: 100%;
    margin-bottom: 50px;
    border: 6px solid orange;
    border-radius: 10px;
}
.text-marginbot-left {
    text-align: left;
    align-self: flex-start;
    margin-bottom: 30px;
    margin-top: -40px;

}

.img-text-full {
    width: 100%;
    margin-bottom: 50px;
    border: 6px solid orange;
    border-radius: 10px;
}

/* Responsive Media Queries */
@media only screen and (min-width: 768px) {
    .text-card-container {
        width: 69%;
    }
    .text-card {
        min-width: 500px;
    }
    .img-text {
        width: 70%;
    }
}