.casino-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;  /* Added to align the h1 and the cards below it */
  margin: 20px;
  font-family: Flama, sans-serif;
  background-color: #1a1c24;
  color: white;
}
.casino-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 2px solid black;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  width: 300px;
  height: 400px;
  background-color: #18141c;
}


.casino-logo,
.casino-name,
.casino-description,
.casino-rating,
.casino-bonus-container,
button {
  
  max-height: 100px; /* You can adjust this value */
}

.casino-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 70%;
}
.casino-card:hover {
  border: 2px solid #f0b40c;
  transition: ease-in 200ms;
}

  
.casino-name {
  margin-bottom: 10px;
  font-size: 17px;
  font-weight: 500;
}

  
.casino-rating {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100px; /* You can adjust this */
  color: #f0b40c;
}
  
  .casino-bonus-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .bonus-label {
    font-size: 12px;
    margin-bottom: -15px;
  }
  
  .casino-bonus {
    width: 200px;
    word-wrap: break-word;
    font-size: 12px;
    text-align: center;
    margin-bottom: 15px;
  }
  .Header-content {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .casino-description {
    margin-bottom: 10px;
    height: 350px !important;
    font-size: 12px;
    font-weight: 100 !important;
    text-align: center;
  }
  button {
    background-color: #f0b40c;
    color: white;
    padding: 10px 20px;
    border: none;
    width: 250px;
    border-radius: 4px !important;
    cursor: pointer;
  }
  .button-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; /* Allow buttons to wrap to the next line on smaller screens */
    gap: 10px;
  }
  .header-button {
    margin: 5px;
    padding: 10px 20px;
    background-color: #f0b40c;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
  }
  
  button:hover {
    background-color: #f3c33d;
    transition: ease-in 200ms;
  }

  
  .casino-logo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .casino-header {
    display: flex;
    align-items: center;
    width: 170px;
  }

  .rating-label {
    font-size: 12px !important;  /* Adjust this value as needed */
  }

  .Header-content-margin {
    margin-top: 60px;
  }

  @media screen and (max-width: 768px) {
    .casino-card {
      width: 100%;
      padding: 15px;
    }
    .casino-card-container {
      max-width: 100%;
      padding: 0 10px;
    }
    .casino-logo {
      width: 40px;
      height: 40px;
    }
    .casino-name {
      font-size: 16px;
    }
  }
  
  /* Media Query for Mobile Devices */
  @media screen and (max-width: 480px) {
    .casino-card {
      width: 100%;
      padding: 10px;
    }
    .casino-logo {
      width: 30px;
      height: 30px;
    }
    .casino-name {
      font-size: 14px;
    }
    .casino-description {
      font-size: 11px;
    }
    .bonus-label, .rating-label {
      font-size: 10px;
    }
    .casino-bonus {
      font-size: 10px;
    }
    button {
      width: 100%;
      padding: 8px 16px;
    }
  }