.footer {
    background-color: #1a1c24;
    color: white;
    padding: 20px;
    text-align: center;
}

.footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 80%;
    margin: auto;
}

.footer-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Vertically center content */
    margin-bottom: 20px;
}

.footer-section img {
    max-width: 100%;
    max-height: 100%;
}

.footer-title {
    font-size: 20px;
}

.footer-small-title {
    font-size: 14px;
    text-decoration: none;  
    color: white;
}

.footer-small-title:hover {
    color: #f0b40c;
    cursor: pointer;
    transition: ease-in 200ms;
}

.social-media-icons {
    margin-top: 10px;
}

.social-media-icons a {
    margin: 0 10px;
    color: white;
}

.social-media-icons a:hover svg {
    color: #f0b40c !important;
    cursor: pointer;
    transition: color 200ms ease-in;
}

/* Media Query for Tablets */
@media screen and (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
    }
    .footer-section {
        margin-bottom: 20px;
    }
}

/* Media Query for Mobile Devices */
@media screen and (max-width: 480px) {
    .footer-content {
        width: 100%;
    }
    .footer-section {
        margin-bottom: 10px;
    }
}

.footer-section a {
    display: inline-block; /* or 'block' depending on your layout */
    margin: 0;
    padding: 0;
}

